import {
  AdaptiveComponent,
  AdaptiveRow,
  Btn,
  Center,
  Colors,
  Column,
  MutedDivider,
  Row,
  Txt,
  VerticalDivider
} from "mesa-ui";
import {CompanyName, contactColor} from "./DesignFirmScreen";
import {useState} from "react";
import {Modal} from "native-base";

export function ForrePageHeader() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <AdaptiveRow
        style={{
          margin: 5,
          justifyContent: "space-between",
          // flexWrap: "wrap"
        }}
        space={"md"}
      >
        <Row
          variant={"ghost"}
          style={{
            justifyContent: "center"
          }}
        >
          <Btn
            variant={"ghost"}
            colorScheme={"white"}
          >
            <Row
              style={{
                alignItems: "flex-end"
              }}
            >
              <Txt
                style={{
                  // fontFamily: "Inter_900Black"
                  fontWeight: 1000
                }}
                variant={"subtitle"}
              >
                {CompanyName}
              </Txt>
            </Row>
          </Btn>
        </Row>
        <Row
          style={{
            justifyContent: "flex-end"
          }}
        >
          <AdaptiveComponent
            sm={null}
            md={
              <Btn
                onPress={() => setShowModal(true)}
                variant={"solid"}
                style={{
                  borderRadius: 10,
                  borderColor: contactColor,
                }}
                _dark={{
                  backgroundColor: contactColor,
                }}
                _light={{
                  backgroundColor: contactColor,
                }}
                _hover={{
                  opacity: .9,
                }}
                _pressed={{
                  opacity: 1,
                  backgroundColor: "#3d349b",
                }}
                color={contactColor}
                colorScheme={"muted"}
              >
                <Txt
                  bold={true}
                  // color={contactColor}
                >
                  Contact
                </Txt>
              </Btn>
            }
          />
        </Row>
      </AdaptiveRow>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}
        style={{flex: undefined}}
      >
        <Row
          style={{
            backgroundColor: Colors.white,
            borderRadius: 10,
            padding: 20,
            // minHeight: 200,
            maxHeight: "90%"
          }}
        >
          <Center style={{flexDirection: "column"}}>
            <Row style={{padding: 5}}>
              <Txt variant={"title"} color={contactColor}>Email</Txt>
              <VerticalDivider color={Colors.muted5} style={{width: 2, marginHorizontal: 10}}/>
              <Txt variant={"title"} color={contactColor}>info@forredesign.com</Txt>
            </Row>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSdFDdS-wqXRJUB-9Giod3GBSvkQa2HwLlFe7ujvdjPjsBCHMw/viewform?embedded=true"
              width="400" height="679" frameBorder="0" marginHeight="0" marginWidth="0"
            >Loading…
            </iframe>
          </Center>
        </Row>
      </Modal>
    </>
  )
}