import {Btn, CardImage, Center, Colors, Column, HorizontalCard, MutedDivider, Row, Txt} from "mesa-ui";
import {useFonts} from "expo-font";
import {useWindowDimensions, View} from "react-native";
import {min} from "lodash";
import {Parallax, ParallaxLayer} from "@react-spring/parallax";
import {ProjectCards} from "./ProjectCards";
import {Suspense} from "react";
import {CardCarousel} from "./CardCarousel";
import {BgStroke} from "./BgStroke";
import {CardRow} from "./CardRow";
import {BlurView} from "expo-blur";
import {ForrePageHeader} from "./ForrePageHeader";

export const contactColor = "#a195e5"
const InfoFontSize = 25;
const IntraPageItemPadding = 20;
const headerFontSize = 45;
const titleFontSize = 40;
const nameColor = Colors.lightText
const InfoFontProps = {
  color: Colors.muted["500"],
  padding: 20,
  fontSize: InfoFontSize,
  fontWeight: "600",
  paddingHorizontal: 10,
  textAlign: "center"
}
export const CompanyName = "FORRE"
const parallaxSpeed = .1;
const projectCardsOffset = 0;

export function DesignFirmScreen() {

  // const alignCenter = { display: 'flex', alignItems: 'center' }
  const {width: windowWidth, height: windowHeight} = useWindowDimensions()
  const windowKey = 1//`${windowWidth}:${windowHeight}`
  const mobileMode = windowWidth * 0.66 < 250 * 2// || width > windowHeight * 2 / 3
  const adaptiveWidth = min([windowWidth - 50, 400])!!
  const verticalCardWidth = mobileMode ? adaptiveWidth * .7 / 2 : 180
  const verticalCardHeight = verticalCardWidth * 3 / 2
  const cardNum = 5.75
  const projectCardPixelOffset = verticalCardHeight * (cardNum) + projectCardsOffset
  const idkCompensation = 0

  const parallaxOffset = (projectCardPixelOffset / (windowHeight - 100)) + idkCompensation
  const holisticOffset = (800 / windowHeight)


  const parallaxInner = (
    <>
      <ParallaxLayer
        speed={parallaxSpeed}
        sticky={
          mobileMode ?
            {
              start: .1,
              end: .5
            } : {
              start: 0,
              end: .75
            }
        }
      >
        <Center
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <Center
            style={{
              position: "absolute",
              bottom: "60%",
              paddingHorizontal: 5,
            }}
          >
            <Txt
              style={{
                fontSize: titleFontSize,
                // letterSpacing: 1.5,
                textAlign: "center",
                // fontFamily: "Montserrat_500Medium",
                // fontFamily: "Inter_900Black"
              }}
            >
              <Txt
                style={{
                  fontSize: titleFontSize,
                  letterSpacing: 1.5,
                  textAlign: "center",
                  // fontFamily: "Inter_900Black",
                  fontWeight: 1000,
                  paddingRight: 3
                }}
                variant={"title"}
              >{CompanyName}</Txt>
              Design
            </Txt>
            <Txt
              style={{
                ...InfoFontProps,
                paddingTop: 8,
                // letterSpacing: 1.5,
                textAlign: "center",
                color: contactColor
              }}
            >
              Design without boundaries.
            </Txt>
          </Center>
        </Center>
      </ParallaxLayer>

      <ParallaxLayer
        speed={parallaxSpeed}
        sticky={
          mobileMode ?
            {
              start: .5,
              end: .5
            } : {
              start: .5,
              end: .75
            }
        }
      >
        <Center
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <Center
            style={{
              // paddingTop: IntraPageItemPadding,
              position: "absolute",
              top: "40%",
              width: "100%",
            }}
          >
            <CardRow
              snapToInterval={adaptiveWidth}
            >
              <HorizontalCard
                width={adaptiveWidth}
                content={
                  <video
                    style={{
                      height: "100%",
                      objectFit: "fill"
                    }}
                    src={require('../assets/videos/metal-bottle-clip.mp4')}
                    autoPlay={true}
                    playsInline={true}
                    muted={true}
                    loop={true}
                    controls
                  />
                }
              />
              <HorizontalCard
                width={adaptiveWidth}
                content={
                  <video
                    style={{
                      height: "100%",
                      objectFit: "fill"
                    }}
                    src={require('../assets/videos/ufo-rise-clip.mp4')}
                    // autoPlay={true}
                    playsInline={true}
                    muted={true}
                    loop={true}
                    controls
                  />
                }
              />
              <HorizontalCard width={adaptiveWidth}>
                <BlurView
                  tint={"dark"}
                  intensity={65}
                  style={{
                    borderRadius: 10,
                    padding: 10,
                    flex: 1
                  }}
                >
                  <Center>
                    <Txt
                      color={Colors.lightText}
                      variant={"subtitle"}
                      textAlign={"center"}
                    >
                      We provide a one stop solution for creating unique and compelling branded products
                      {"\n\n"}
                      Whether it's customizing existing products or designing new ones, we have the team to meet your needs
                    </Txt>
                  </Center>
                </BlurView>
              </HorizontalCard>
            </CardRow>
            <Txt
              style={{
                ...InfoFontProps,
                // letterSpacing: 1.5,
                textAlign: "center",
              }}
            >
              From
              <Txt> ideation </Txt>
              to
              <Txt> manufacturing </Txt>
              and beyond, our expertise is in bringing concepts to life
            </Txt>
          </Center>
        </Center>
      </ParallaxLayer>


      <ParallaxLayer
        speed={.15}
        offset={2.25}
        style={{overflow: "visible"}}
      >
        <BgStroke/>

      </ParallaxLayer>
      {/*<ParallaxLayer*/}
      {/*  // speed={1}*/}
      {/*  offset={2}*/}
      {/*  style={{*/}
      {/*    pointerEvents: "none"*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Column*/}
      {/*    style={{*/}
      {/*      paddingVertical: 10,*/}
      {/*      paddingRight: mobileMode ? 0 : 0,*/}
      {/*      alignItems: "center",*/}
      {/*      width: "100%",*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Txt*/}
      {/*      style={{*/}
      {/*        fontSize: titleFontSize,*/}
      {/*        // fontFamily: "Inter_900Black",*/}
      {/*        paddingRight: 20*/}
      {/*      }}*/}
      {/*      // bold={true}*/}
      {/*      variant={"title"}*/}
      {/*    >*/}
      {/*      design*/}
      {/*    </Txt>*/}
      {/*    <Txt*/}
      {/*      style={{*/}
      {/*        fontSize: titleFontSize,*/}
      {/*        paddingLeft: 96,*/}
      {/*        // fontFamily: "Inter_900Black",*/}
      {/*      }}*/}
      {/*      // bold={true}*/}
      {/*      variant={"title"}*/}
      {/*    >*/}
      {/*      without*/}
      {/*    </Txt>*/}
      {/*    <Txt*/}
      {/*      style={{*/}
      {/*        fontSize: titleFontSize,*/}
      {/*        // fontFamily: "Inter_900Black",*/}
      {/*        paddingRight: 30*/}
      {/*      }}*/}
      {/*      // bold={true}*/}
      {/*      variant={"title"}*/}
      {/*    >*/}
      {/*      boundaries*/}
      {/*    </Txt>*/}
      {/*  </Column>*/}
      {/*</ParallaxLayer>*/}

      <ParallaxLayer
        offset={2.1}
        speed={parallaxSpeed}
      >
        <View
          style={{
            paddingVertical: projectCardsOffset,
          }}
        >
          <Delayed waitBeforeShow={500}>
            <ProjectCards mobileMode={mobileMode}/>
          </Delayed>
        </View>
      </ParallaxLayer>


      <ParallaxLayer
        speed={parallaxSpeed}
        offset={2.1 + parallaxOffset}
        // sticky={
        //   simpleScrollMode ? undefined : {
        //     start: 2 + parallaxOffset,
        //     end: 3 + parallaxOffset
        //   }
        // }
      >
        <Center
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <Txt
            style={{
              fontSize: titleFontSize,
              letterSpacing: 1.5,
              textAlign: "center"
              // fontFamily: "Inter_900Black"
            }}
            bold={true}
            variant={"title"}
          >
            Interdisciplinary Expertise
          </Txt>
          <Txt
            style={{
              ...InfoFontProps,
              // letterSpacing: 1.5,
              textAlign: "center"
            }}
          >
            We have expertise in a wide range of domains involved in product development
          </Txt>
          <CardRow
            snapToInterval={adaptiveWidth}
          >
            <HorizontalCard
              header={"Head of Design"}
              width={adaptiveWidth}
              content={<CardImage imageSrc={require('../assets/profile/profile-card-hugo.png')}/>}
              caption={
                <Txt
                  color={Colors.lightText}
                  variant={"subtitle"}
                  textAlign={"center"}
                >
                  Award winning industrial designer,
                  <Txt
                    // fontFamily={"Inter_900Black"}
                    fontWeight={1000}
                    color={nameColor}
                  > Hugo Kun, </Txt>
                  also runs the Taiwan based consumer product company,
                  <Txt
                    // fontFamily={"Inter_900Black"}
                    fontWeight={1000}
                    color={Colors.lightText}
                  > Cheer 1988</Txt>
                </Txt>
              }
            />
            {/*<HorizontalCard*/}
            {/*  header={"Head of Operations"}*/}
            {/*  width={adaptiveWidth}*/}
            {/*  content={<CardImage imageSrc={require('../assets/profile/profile-card-blank.png')}/>}*/}
            {/*  caption={*/}
            {/*    <Txt*/}
            {/*      color={Colors.lightText}*/}
            {/*      variant={"subtitle"}*/}
            {/*      textAlign={"center"}*/}
            {/*    >*/}
            {/*      <Txt*/}
            {/*        fontWeight={1000}*/}
            {/*        // fontFamily={"Inter_900Black"}*/}
            {/*        color={nameColor}*/}
            {/*      > Derek Wong</Txt>*/}
            {/*      , a California based software engineer*/}
            {/*      with a passion for art, design, and 3d graphics*/}
            {/*      /!*and the creator of*!/*/}
            {/*      /!*<Txt*!/*/}
            {/*      /!*  fontFamily={"Inter_900Black"}*!/*/}
            {/*      /!*  color={Colors.lightText}*!/*/}
            {/*      /!*> Star Mesa*!/*/}
            {/*      /!*</Txt>*!/*/}
            {/*      /!*, a web based interactive 3D platform*!/*/}
            {/*    </Txt>*/}
            {/*  }*/}
            {/*/>*/}
            <HorizontalCard
              // header={"Team Members"}
              width={adaptiveWidth}
              content={<CardImage imageSrc={require('../assets/images/filler-bg.png')}/>}
            >
              <BlurView
                tint={"dark"}
                intensity={65}
                style={{
                  borderRadius: 10,
                  padding: 5,
                  flex: 1
                }}
              >
                <Center>
                  <Txt
                    color={Colors.lightText}
                    variant={"subtitle"}
                    textAlign={"center"}
                  >
                    Our global team has expertise in:
                    <Txt
                      // fontFamily={"Inter_900Black"}
                      color={nameColor}
                    >
                      {"\n\nManufacturing\nDigital marketing\nLogistics\nGraphical design\n3d modeling\nIndustrial engineering\n\n"}
                    </Txt>
                    and more.
                  </Txt>
                </Center>
              </BlurView>
            </HorizontalCard>
            {/*<HorizontalCard*/}
            {/*  header={"Head of Manufacturing"}*/}
            {/*  width={adaptiveWidth}*/}
            {/*>*/}
            {/*  <Txt*/}
            {/*    color={Colors.lightText}*/}
            {/*    variant={"subtitle"}*/}
            {/*    textAlign={"center"}*/}
            {/*  >*/}
            {/*    Mr.*/}
            {/*    <Txt*/}
            {/*      fontFamily={"Inter_900Black"}*/}
            {/*      color={nameColor}*/}
            {/*    > Kun Zi Fai </Txt>*/}
            {/*    owns the Hong Kong based manufacturing company,*/}
            {/*    <Txt*/}
            {/*      fontFamily={"Inter_900Black"}*/}
            {/*      color={Colors.lightText}*/}
            {/*    > TODO </Txt>*/}
            {/*  </Txt>*/}
            {/*</HorizontalCard>*/}
          </CardRow>
          <Txt
            style={{
              ...InfoFontProps,
              // letterSpacing: 1.5,
              textAlign: "center"
            }}
          >
            <Txt> 50+ years </Txt>
            combined experience in design and manufacturing
          </Txt>
        </Center>
      </ParallaxLayer>

      <ParallaxLayer
        speed={parallaxSpeed}
        offset={2.75 + parallaxOffset + holisticOffset}
      >
        <Center
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <Center
            style={{
              position: "absolute",
              bottom: "45%",
              paddingHorizontal: 5,
              overflow: "hidden"
            }}
          >

            <Txt
              style={{
                fontSize: titleFontSize,
                letterSpacing: 1.5,
                textAlign: "center"
                // fontFamily: "Inter_900Black"
              }}
              bold={true}
              variant={"title"}
            >
              Pragmatic Design
            </Txt>
            <Txt
              style={{
                ...InfoFontProps,
                // letterSpacing: 1.5,
                textAlign: "center"
              }}
            >
              We believe good design achieves
              <Txt> quality</Txt>,
              <Txt> cost </Txt>effectiveness
              , and
              functional<Txt> ergonomics</Txt>
            </Txt>
            <Row
              style={{
                margin: 0,
                height: 430,
                marginVertical: IntraPageItemPadding * 2,
              }}
            >

            </Row>
          </Center>
        </Center>
      </ParallaxLayer>

      <ParallaxLayer
        speed={parallaxSpeed}
        offset={2.75 + parallaxOffset + holisticOffset}
      >
        <Center
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <Center
            style={{
              position: "absolute",
              bottom: "45%",
              paddingHorizontal: 5,
            }}
          >
            <Row
              style={{
                margin: 0,
                width: 775,
                height: 430,
                marginVertical: IntraPageItemPadding,
              }}
            >
              <Delayed waitBeforeShow={2000}>
                <Suspense>
                  <CardCarousel/>
                </Suspense>
              </Delayed>
            </Row>
        </Center>
        <Center
            style={{
              position: "absolute",
              top: "55%",
              paddingHorizontal: 5,
            }}
          >
          <Txt
            style={{
              ...InfoFontProps,
              // letterSpacing: 1.5,
              textAlign: "center",
              // paddingTop: IntraPageItemPadding
            }}
          >
            <Txt
              color={contactColor}
            >Our Team </Txt>
            is with you every step in realizing your vision
          </Txt>
          </Center>
        </Center>

      </ParallaxLayer>
    </>
  )

  return (
    <Column
      key={windowKey}
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "#ffffff",
        borderStyle: 0,
        scrollBehavior: "smooth",
      }}
    >
      <ForrePageHeader/>
      <MutedDivider/>
      <Column
        style={{
          flex: 1,
          alignContent: "center",
          justifyContent: "center"
        }}
      >
        <Parallax
          pages={3.75 + parallaxOffset + holisticOffset}
        >
          {parallaxInner}
        </Parallax>
      </Column>

    </Column>
  )
}

import React, { useState, useEffect } from 'react';

type Props = {
  children: React.ReactNode;
  waitBeforeShow?: number;
};

const Delayed = ({ children, waitBeforeShow = 500 }: Props) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [waitBeforeShow]);

  return isShown ? children : null;
};

export default Delayed;