import {useWindowDimensions, View, ViewProps} from "react-native";
import {Btn, Column, Row, ScrollRow, Txt} from "mesa-ui";

export function CardRow(
  props: {
    children,
    title?,
    startOffset?: number,
    snapToInterval?: number,
  } & ViewProps
) {
  const {width, height: windowHeight} = useWindowDimensions()
  const mobileMode = width * 0.66 < 250 * 2

  return (
    <Column
      {...props}
      style={{
        maxWidth: "100%",
        paddingHorizontal: mobileMode ? 5 : 10,
        ...props.style,
      }}
    >
      {props.title ?
        <Btn
          variant={"ghost"}
          colorScheme={"white"}
          alignSelf={"start"}
          disabled={true}
        >
          <Row
            style={{
              alignItems: "center",
              padding: 0,
              margin: 0,
            }}
            space={"2xs"}
          >
            <Txt
              variant={"title"}
            >
              {props.title}
            </Txt>
            {/*<Icn*/}
            {/*  icon={"arrow-forward-outline"}*/}
            {/*/>*/}
          </Row>
        </Btn>
        : null
      }
      <ScrollRow
        // disableOverflowHint={mobileMode}
        snapToInterval={props.snapToInterval ? props.snapToInterval + 10 : undefined}
        variant={"ghost"}
        startOffset={props.startOffset}
      >
        <Row space={"xs"}
             style={{
               padding: 0,
               margin: 0
             }}
        >
          {mobileMode ?
            <View/>
            : null
          }
          {props.children}
          {mobileMode ?
            <View/>
            : null
          }
        </Row>
      </ScrollRow>
    </Column>
  )
}