import {View} from "react-native";
import {
  Colors,
  Column,
  Row,
  Txt,
  VerticalCard
} from "mesa-ui"

export function ProjectCards(
  props: {
    mobileMode: boolean
  }
) {

  return (
    <Row
      style={{
        flex: 1,
      }}
      justifyContent={"center"}
    >
      <Column
        style={{
          alignItems: "flex-end",
        }}
      >
        <VerticalCard
          left
          init={!props.mobileMode}
          imageSrc={require('../assets/images/water-bottle.png')}
          header={"UFO Bottle"}
          tags={
            <View
              style={{
                margin: 2.5,
                padding: 5,
                borderRadius: 5,
                backgroundColor: "rgba(0,0,0,0.3)",
              }}
            >
              <Txt
                fontSize={"sm"}
                variant={"subtitle"}
                color={Colors.lightText}
              >
                iF Design Award Winner
              </Txt>
            </View>
          }
        >
          Our award winning UFO bottle design features an adjustable mag-lev infusion tray,
          a stowable straw, and a customizable character toothpick
        </VerticalCard>
        <VerticalCard
          left
          header={"Corrente Humidifier"}
          imageSrc={require('../assets/images/humidifier.webp')}
        >
          This elegant humidifier is from the Corrente line, featuring high quality everyday items suitable for every household
        </VerticalCard>
        <VerticalCard
          left
          header={"Bobakuma Character Brand"}
          imageSrc={require('../assets/images/bobakuma-card.png')}
        >
          Alongside designing for client brands, we've also developed the Bobakuma character brand to promote our products and services

          {"\n\n"}The Bobakuma brand features an adorable cast of creatures who share an easy going personality despite each of their unique quirks
        </VerticalCard>
      </Column>
      <Column
        style={{
          paddingTop: 90,
        }}
      >
        <VerticalCard
          header={"Standard Bento"}
          imageSrc={require('../assets/images/bento.webp')}
        >
          This minimalist bento is designed to be used in single or double stacked configuration
        </VerticalCard>
        <VerticalCard
          header={"Thermal Bottle Concept"}
          imageSrc={require('../assets/images/metal-bottle.png')}
          // contentFit={"contain"}
        >
          Building on our previous work, this concept features a thermal venting system that pushes the limits of our experience working on vacuum technology and precision parts
        </VerticalCard>
        <VerticalCard
          header={"Compact Glass Bottle"}
          imageSrc={require('../assets/images/cup-with-bg.png')}
        >
          Our latest items to go to production, this compact bottle is the perfect portion for coffee, tea, and other hot beverages
        </VerticalCard>
      </Column>
      {/*<Column*/}
      {/*  style={{*/}
      {/*    paddingTop: 270,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <View*/}
      {/*    style={{*/}
      {/*      borderRadius: 10,*/}
      {/*      margin: 5,*/}
      {/*      width: 200,*/}
      {/*      height: 300,*/}
      {/*      borderWidth: 2,*/}
      {/*      borderColor: blankCardColor,*/}
      {/*      borderStyle: "dashed",*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</Column>*/}
    </Row>
  )
}