import * as THREE from 'three'
import {useEffect, useRef, useState} from 'react'
import {Canvas, extend, useFrame} from '@react-three/fiber'
import { Image, ScrollControls, useScroll } from '@react-three/drei'
import { easing } from 'maath'
import {Platform, useWindowDimensions} from "react-native";
import {TextureLoader} from "expo-three";

class BentPlaneGeometry extends THREE.PlaneGeometry {
  constructor(radius, ...args) {
    super(...args)
    let p = this.parameters
    let hw = p.width * 0.5
    let a = new THREE.Vector2(-hw, 0)
    let b = new THREE.Vector2(0, radius)
    let c = new THREE.Vector2(hw, 0)
    let ab = new THREE.Vector2().subVectors(a, b)
    let bc = new THREE.Vector2().subVectors(b, c)
    let ac = new THREE.Vector2().subVectors(a, c)
    let r = (ab.length() * bc.length() * ac.length()) / (2 * Math.abs(ab.cross(ac)))
    let center = new THREE.Vector2(0, radius - r)
    let baseV = new THREE.Vector2().subVectors(a, center)
    let baseAngle = baseV.angle() - Math.PI * 0.5
    let arc = baseAngle * 2
    let uv = this.attributes.uv
    let pos = this.attributes.position
    let mainV = new THREE.Vector2()
    for (let i = 0; i < uv.count; i++) {
      let uvRatio = 1 - uv.getX(i)
      let y = pos.getY(i)
      mainV.copy(c).rotateAround(center, arc * uvRatio)
      pos.setXYZ(i, mainV.x, y, -mainV.y)
    }
    pos.needsUpdate = true
  }
}

extend({ BentPlaneGeometry })

export const CardCarousel = () => {
  const [wait, setWait] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setWait(false)
    }, 1000)
  }, []);

  if (wait) {
    return null
  }

  return (
    <Canvas camera={{ position: [0, 0, 100], fov: 10 }}>
      <ScrollControls
        horizontal
        pages={10}
        infinite
        style={{scrollbarWidth: "none"}}
        // maxSpeed={20}
        // damping={.2}
      >
        <Rig rotation={[0, 0, 0.15]}>
          <Carousel />
        </Rig>
      </ScrollControls>
    </Canvas>
  )
}

function Rig(props) {

  const ref = useRef()
  const scroll = useScroll()
  const {width} = useWindowDimensions()
  const mobileMode = width * 0.66 < 250 * 2


  useFrame((state, delta) => {
    ref.current.rotation.y = -scroll.offset * (Math.PI * 2) * (mobileMode ? -5 : 2) // Rotate contents
    state.events.update() // Raycasts every frame rather than on pointer-move
    easing.damp3(
      state.camera.position,
      [-state.pointer.x * 2, state.pointer.y + 1.5, 10],
      0.3,
      delta
    ) // Move camera
    state.camera.lookAt(0, 0, 0) // Look at center
  })

  useEffect(() => {
    if (!mobileMode) {
      const el = scroll.el

      const onWheel = (e) => {

        e.preventDefault()
        el.scrollTop += e.deltaY

        // slider.scrollBy(e.deltaY, 0);
      };

      el.addEventListener('wheel', onWheel);

      return () => {
        el.removeEventListener('wheel', onWheel);
      }
    }
  }, [mobileMode]);

  return <group ref={ref} {...props} />
}

const imageUrls = [
  require('../assets/squares/kuma-plushie-square.png'),
  require('../assets/squares/toothpicks.png'),
  require('../assets/squares/bento-meal.jpg'),
  require('../assets/squares/bobakuma-square.png'),
  require('../assets/squares/racoon-square.png'),
  require('../assets/squares/bento-big.png'),
  require('../assets/squares/metal-bottle-set.png'),
  require('../assets/squares/shelf-mockup-square.png'),
]

function Carousel({ radius = 1.4, count = 8 }) {


  return imageUrls.map((url, i) => {
    return <Card
      key={i}
      url={imageUrls[i]}
      position={[Math.sin((i / count) * Math.PI * 2) * radius, 0, Math.cos((i / count) * Math.PI * 2) * radius]}
      rotation={[0, Math.PI + (i / count) * Math.PI * 2, 0]}
    />
  })
}

function Card({ url, ...props }) {
  const ref = useRef()
  const [hovered, hover] = useState(false)
  const pointerOver = (e) => (e.stopPropagation(), hover(true))
  const pointerOut = () => hover(false)
  useFrame((state, delta) => {
    easing.damp3(ref.current.scale, hovered ? 1.1 : 1, 0.1, delta)
    easing.damp(ref.current.material, 'radius', 0.1, 0.2, delta)
    easing.damp(ref.current.material, 'zoom', hovered ? 1 : 1.5, 0.2, delta)
  })
  // const imgTexture = new TextureLoader().load(require('../assets/images/bento.webp'))

  // return  null

  return (
    <Image
      ref={ref}
      url={url}
      // texture={imgTexture}
      transparent
      // side={THREE.DoubleSide}
      onPointerOver={pointerOver}
      onPointerOut={pointerOut}
      {...props}
    >
      <bentPlaneGeometry args={[0.1, 1, 1, 20, 20]} />
    </Image>
  )
}


