import {Colors, lightTheme, NativeBaseProvider, SelectProvider, TxtProvider} from "mesa-ui"
import {DesignFirmScreen} from "./components/DesignFirmScreen";
import ReactGA from "react-ga4";

ReactGA.initialize("G-H2BPY05G2Y");

export default function App() {
  return (
    <>
      <NativeBaseProvider theme={lightTheme}>
        <TxtProvider
          // fontFamily={"font-mono"}
          color={Colors.black}
        >
          <SelectProvider>
            <DesignFirmScreen/>
          </SelectProvider>
        </TxtProvider>
      </NativeBaseProvider>
    </>
  )
}


